import { cloneElement, useEffect, useRef, useId, useState } from "react";
import { add, format, formatRFC3339, isToday, sub } from "date-fns";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Divider,
  DialogActions,
  Button,
  Autocomplete,
  Typography,
  LinearProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useToggle from "libs/hooks/useToggle";
import useDelayValue from "libs/hooks/useDelayValue";
import { useMemberService, useOldPetService, useUserService, useReservationService } from "service";
import useSubmit from "libs/hooks/useSubmit";
import PreDataAlert from "./PreDataAlert.js";
import { SelectManipulateType } from "components/Input/Select";
import { RegularDatePicker } from "components/Input/DatePicker";
import TimePicker from "components/Input/old_TimePicker";

const currentDate = date => (date ? format(new Date(date), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"));
const currentTime = date => (date ? format(new Date(date), "HH:mm") : format(new Date(), "HH:mm"));

/**
 * @param {string} reserveId - 當有 reserveId 代表要編輯惹
 * @param {any} pre-* - 預先填入的內容
 */
export default function ReservingDialog({
  button,
  isOpen,
  onClose,
  reserveId,
  preMemberId,
  prePetId,
  preDate,
  preStartTime,
  preEndTime,
  onAdd,
  onUpdate,
}) {
  const initRef = useRef(true);
  const formId = useId();
  const [isPending, handleSubmit] = useSubmit();
  const { useOneReservation, addReservation, editReservation, onlyDescriptionEditReservation } = useReservationService();
  const { useAllUser } = useUserService();
  const { useAllPet, useOnePet } = useOldPetService();
  const { useOneMember } = useMemberService();

  const [open, toggle] = useToggle(isOpen);

  const [searchPetName, delaySearchPetName, setSearchPetName, isSearchPetNameLoading] = useDelayValue("");
  const [searchPetByMemberPhone, delaySearchPetByMemberPhone, setSearchPetByMemberPhone, isSearchPetByMemberPhoneLoading] =
    useDelayValue("");

  const isPetSearchLoading = isSearchPetNameLoading || isSearchPetByMemberPhoneLoading;

  const { data: pet } = useOnePet(prePetId, null, Boolean(open && prePetId));
  const { data: member } = useOneMember(preMemberId, null, Boolean(open && preMemberId));

  const { data: users } = useAllUser({ query: new URLSearchParams({ isDoctor: 1, isBlocked: 0, limit: "zero" }), isAble: open });

  const { data: pets } = useAllPet(
    {
      deleted: 0,
      petName: delaySearchPetName,
      phone: delaySearchPetByMemberPhone,
      limit: 25,
    },
    null,
    Boolean(open && !reserveId && !preMemberId),
  );

  const { data: reservation, mutate: mutateReservationData } = useOneReservation(reserveId, null, Boolean(open && reserveId));

  const isSoftEdit = reservation && reserveId && reservation.status === "候診中";
  // when 看診中只能編輯備註
  const isStrictEdit = reservation && reserveId && reservation.status !== "尚未報到" && reservation.status !== "候診中";

  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);

  const [date, setDate] = useState(preStartTime || new Date());
  const [startTime, setStartTime] = useState(preStartTime || new Date());
  const [endTime, setEndTime] = useState(preEndTime || add(new Date(), { minutes: 30 }));

  const [isAllDay, setIsAllDay] = useState(false);

  // controlled prop `isOpen`
  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }

    if (isOpen) {
      toggle.toTrue();
    } else {
      toggle.toFalse();
    }
  }, [isOpen]);

  // leave and clean up
  useEffect(() => {
    return () => {
      setSearchPetName("");
      setSearchPetByMemberPhone("");
      setSelectedDoc(null);
      setSelectedPet(null);
      setStartTime("");
      setDate(preStartTime || new Date());
      setStartTime(preStartTime || new Date());
      setEndTime(preEndTime || add(new Date(), { minutes: 30 }));
      setIsAllDay(false);
    };
  }, [open]);

  useEffect(() => {
    if (reservation) {
      setSelectedDoc(reservation.employee);
      setDate(reservation.calendar.startDate);
      setStartTime(reservation.calendar.startDate);
      setEndTime(reservation.calendar.endDate);
      setIsAllDay(reservation.calendar.allDay === "true");
    }
  }, [reservation]);

  const handleClose = () => {
    mutateReservationData();
    toggle();
    onClose?.();
  };

  const handleConfirm = evt => {
    evt.preventDefault();
    const form = new FormData(evt.target);
    const formObj = {};
    for (const [k, v] of form) {
      formObj[k] = v;
    }

    if (isSoftEdit) {
      handleSubmit(
        editReservation.bind(null, reserveId, {
          description: formObj.description,
          employeeId: selectedDoc.userId,
          reserveStart: isAllDay ? format(new Date(reservation.reserveStart), "yyyy-MM-dd") : reservation.reserveStart,
          reserveEnd: isAllDay ? format(new Date(reservation.reserveEnd), "yyyy-MM-dd") : reservation.reserveEnd,
          allDay: isAllDay,
        }),
        () => onUpdate?.(),
        handleClose,
      );
      return;
    }

    if (isStrictEdit) {
      handleSubmit(
        onlyDescriptionEditReservation.bind(null, reserveId, {
          description: formObj.description,
        }),
        () => onUpdate?.(),
        handleClose,
      );
      return;
    }

    // date pre-process
    const [year, month, day] = formObj.date.split("-");

    const prepareData = { reserveType: +formObj.reserveType, allDay: isAllDay };
    // reserveType
    prepareData.isReserve = Number(formObj.isReserve);
    // doctor
    prepareData.employeeId = selectedDoc.userId;

    // means new edit
    if (!reserveId) {
      //doctor's related info
      prepareData.salePointId = selectedDoc.salePointId;
      prepareData.departmentId = selectedDoc.departmentId;
      // pet & member
      prepareData.petIdentityId = prePetId || selectedPet.id;
      prepareData.memberId = pet ? pet.memberId : selectedPet.memberId;
    }

    // date & time
    if (isAllDay) {
      prepareData.reserveStart = formObj.date;
      prepareData.reserveEnd = formObj.date;
    } else {
      prepareData.reserveStart = formatRFC3339(new Date(year, Number(month - 1), day, ...formObj.startTime.split(":")));
      prepareData.reserveEnd = formatRFC3339(new Date(year, Number(month - 1), day, ...formObj.endTime.split(":")));
    }

    // description
    prepareData.description = formObj.description;

    if (reserveId) {
      handleSubmit(editReservation.bind(null, reserveId, prepareData), () => onUpdate?.(), handleClose);
      return;
    }

    handleSubmit(addReservation.bind(null, prepareData), () => onAdd?.(), handleClose);
  };

  return (
    <>
      {button && cloneElement(button, { onClick: toggle })}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{reserveId ? "編輯預約" : "新增預約"}</DialogTitle>

        <DialogContent key={reservation ? reservation.id : "NEW"}>
          <form id={formId} onSubmit={handleConfirm}>
            <Stack mt={1} spacing={2}>
              {!reserveId && !prePetId && !preMemberId && (
                <TextField label="飼主電話搜尋" onChange={e => setSearchPetByMemberPhone(e.target.value)} />
              )}
              {reserveId && reservation && <PreDataAlert petName={reservation.pet.petName} memberName={reservation.member.memberName} />}
              {pet && <PreDataAlert petName={pet.petName} memberName={pet.member.memberName} />}
              {!pet && member && <PreDataAlert memberName={member.memberName} />}
              <Divider>預約表單</Divider>

              <SelectManipulateType required name="reserveType" defaultValue={reservation?.reserveType} disabled={reservation} />
              {!reserveId && !prePetId && (
                <Autocomplete
                  value={selectedPet}
                  onChange={(e, newValue) => setSelectedPet(newValue)}
                  inputValue={searchPetName}
                  onInputChange={(e, newValue) => setSearchPetName(newValue)}
                  options={pets?.list || member?.pets || []}
                  getOptionLabel={option => option.petName}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="選擇寵物"
                      required
                      helperText={searchPetByMemberPhone ? `已套用電話號碼 ${searchPetByMemberPhone} 搜尋` : ""}
                    />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <Stack
                        {...props}
                        key={`reserve_opt_${option.id}`}
                        direction="row"
                        spacing={1}
                        justifyContent="space-between!important"
                      >
                        <Typography sx={{ flex: 1 }}>{option.petName}</Typography>
                        <Stack direction="row" spacing={1} sx={{ flex: 1, justifyContent: "flex-end", opacity: 0.5 }}>
                          <Typography>{option.petCode}</Typography>
                          {option.originPetCode ? <Typography>|</Typography> : null}
                          {option.originPetCode ? <Typography>{option.originPetCode}</Typography> : null}
                        </Stack>
                        <Divider flexItem orientation="vertical" />
                        <Stack direction="row" spacing={1} sx={{ flex: 1, justifyContent: "flex-end" }}>
                          <Typography sx={{ textAlign: "right" }}>{option?.member?.memberName || ""}</Typography>
                          <Typography sx={{ opacity: 0.5 }}>{option?.member?.memberCode || ""}</Typography>
                        </Stack>
                      </Stack>
                    );
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  loading={isPetSearchLoading}
                  loadingText={<LinearProgress />}
                />
              )}
              <Autocomplete
                value={selectedDoc}
                onChange={(e, newValue) => setSelectedDoc(newValue)}
                options={users?.list || []}
                getOptionLabel={option => option.realName}
                renderInput={params => <TextField {...params} label="選擇醫師" required />}
                renderOption={(props, option) => (
                  <Stack {...props} direction="row" justifyContent="space-between!important">
                    <Typography>{option.realName}</Typography>
                    <Typography>{option.userId}</Typography>
                  </Stack>
                )}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                disabled={isStrictEdit}
              />
              <RegularDatePicker
                name="date"
                value={date}
                onChange={e => setDate(e)}
                openTo="day"
                required
                disabled={isSoftEdit || isStrictEdit}
              />
              <FormControlLabel
                disabled={isSoftEdit || isStrictEdit}
                control={<Checkbox checked={isAllDay} onChange={e => setIsAllDay(e.target.checked)} />}
                label="全天"
              />
              {!isAllDay && (
                <>
                  <TimePicker
                    label="起始時間"
                    name="startTime"
                    value={startTime}
                    onChange={nv => {
                      setStartTime(nv);
                      setEndTime(add(nv, { minutes: 30 }));
                    }}
                    required
                    disabled={isSoftEdit || isStrictEdit}
                  />
                  <TimePicker
                    label="結束時間"
                    name="endTime"
                    value={endTime}
                    onChange={nv => setEndTime(nv)}
                    required
                    disabled={isSoftEdit || isStrictEdit}
                    minTime={startTime}
                  />
                </>
              )}
              {!reserveId && (
                <TextField label="預約方式" select required name="isReserve" defaultValue="">
                  <MenuItem value="">清除</MenuItem>
                  <MenuItem value="1">現場掛號</MenuItem>
                  <MenuItem value="0">電話預約</MenuItem>
                </TextField>
              )}
              <TextField label="備註" multiline rows={3} name="description" defaultValue={reservation ? reservation.description : ""} />
            </Stack>
          </form>
        </DialogContent>

        <DialogActions>
          <Button type="submit" form={formId}>
            確認
          </Button>
          <Button color="error" onClick={handleClose}>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
